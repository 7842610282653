import { WindowLocation } from "@reach/router"
import React from "react"
import FacebookLogin from "react-facebook-login"
import SimpleLayout from "../components/simpleLayout"

export default function Login({
  location,
  noRedirect,
}: {
  location: WindowLocation
  noRedirect?: boolean
}) {
  const responseFacebook = (response: any) => {
    alert("welkom")
    console.log(response)
  }

  console.log("render")

  return (
    <SimpleLayout title="Login" location={location}>
      <FacebookLogin
        appId="503745347606524"
        fields="name,email"
        callback={responseFacebook}
      />
    </SimpleLayout>
  )
}
